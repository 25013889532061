<template>
    <layout>
        <div class="dashboard mt-5">
            <div class="d-none d-lg-flex justify-space-between align-center px-10">
                <h1 class="heading-2">My quotes</h1>
                <v-btn x-large class="secondary px-12 py-8 text-14 text-transform-none" depressed :to="{name: 'account.quote'}">Start new quote</v-btn>
            </div>
            <v-row class="main mt-5 px-lg-10">
                <v-col cols="12" lg="7" class="">
                    <v-card v-for="(load, index) in quotes" :key="index" class="px-4 px-md-7 py-4 mt-7" flat>
                        <v-card-text class="pa-0">
                            <div class="top d-flex justify-space-between align-center pb-4 mb-5">
                                <span v-if="dateCompare(dateFormat(load.updated_at, 3), dateFormat(), '<')" class="badge py-2 px-4 expired">Expired</span>
                                <span v-else class="badge py-2 px-4 active">Active</span>
                                <div class="date">
                                    Quoted on {{dateFormat(load.updated_at)}}
                                </div>
                            </div>
                            <div class="content">
                                <div class="text-18-75">
                                    {{ getCity(load) }} <v-icon>mdi-chevron-right</v-icon> {{ getCity(load, 'to') }}
                                </div>
                                <div class="d-flex align-end">
                                    <div class="d-flex w-100 justify-space-between mt-4 flex-column">
                                        <div v-for="item in filterItems(load)">
                                            <div class="text-14-60 mb-1">
                                                {{ item.description }}
                                            </div>
                                            <div class="text-14 mb-3">
                                                {{ item.quantity }} Bundles, {{ item.quantity * item.weight }} pounds
                                            </div>
                                        </div>
                                    </div>
                                    <div class="w-100 d-none d-md-block">
                                        <div class="text-right mb-1">
                                            Rates starting at
                                        </div>
                                        <div class="text-18-75 text-right mb-4">
                                            $14.15
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex justify-space-between mt-4 align-end">
                                    <div class="d-none d-md-block">
                                        <div class="text-14-60">
                                            Ship Date
                                        </div>
                                        <div class="">
                                            {{dateFormat(load.items[0].date)}}
                                        </div>
                                    </div>
                                    <div class="d-block d-md-none">
                                        <div class="">
                                            Rates starting at
                                        </div>
                                        <div class="text-18-75">
                                            $14.15
                                        </div>
                                    </div>
                                    <div class="">
                                        <v-btn x-large class="secondary px-8 py-4 text-14 text-transform-none d-none d-md-flex" depressed :to="{name: 'account.quote', params: {id: load.id}}">Resume quote</v-btn>
                                        <v-btn text class="text-14 text-transform-none d-flex d-md-none blue-text px-0" :to="{name: 'account.quote', params: {id: load.id}}">Resume quote <v-icon>mdi-chevron-right</v-icon></v-btn>
                                    </div>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </layout>
</template>

<script>
import Layout from '@/components/layouts/AccountLayout.vue'
export default {
    middleware: 'auth',
    components: {
        Layout,
    },
    data: () => ({
        quotes: [],
    }),
    mounted() {
        this.getLoads('quotes');
    },
    methods: {
        async getLoads(type = 'orders', take = 6, skip = 0){
            var uri = process.env.VUE_APP_API + 'loads/v1/' + type + '?take=' + take + '&skip=' + skip;

            await this.axios.get(uri).then(response => {
                this[type] = Object.assign({}, this[type], response.data);
            }).catch();
        },
        dateFormat(toFormat = false, dayPlus = 0){
            var obj = new Date();
            if(toFormat)
                obj = new Date(toFormat);
            let month = obj.getMonth() + 1;
            let date = obj.getDate() + dayPlus;
            let year = obj.getFullYear();
            return month + '/' + date + '/' + year;
        },
        dateCompare(date1, date2, compare){
            let d1 = new Date(date1)
            let d2 = new Date(date2)

            if(compare == '>'){
                return d1 > d2;
            }
            if(compare == '<'){
                return d1 < d2;
            }
        },
        filterItems(model){
            let items = model.items.filter(function(item){
                return item.is_shipper == 1;
            })
            return items
        },
        getCity(model, type = 'from'){
            let warehouse = model.items.filter(function(item){
                if(type == 'from')
                    return item.is_shipper == 1;
                return item.is_shipper == 0;
            })

            if(warehouse[0])
                return warehouse[0].warehouse.state + ', ' + warehouse[0].warehouse.city;
            return 'not set'
        }

    }
}
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';
.v-card{
    .top{
        border-bottom: 1px dashed #CDE0FF;

    }
    .content{
        .mw-60{
            max-width: 60%;
        }
    }
}
</style>
